import React, { useEffect, useRef, useState } from 'react'
import { MenuContainer } from "./styles";
import PrimeraPortada from "../../images/arquitectura_2023_2/Sala 1.png.jpg";
import SegundaPortada from "../../images/arquitectura_2023_2/Sala 2.png.jpg";
import TerceraPortada from "../../images/arquitectura_2023_2/Sala 3.png.jpg";
import CuartaPortada from "../../images/arquitectura_2023_2/Sala 4.png.jpg";
import QuintaPortada from "../../images/arquitectura_2023_2/Sala 5.png.jpg";
import NivelacionPortada from "../../images/arquitectura_2023_2/Nivelacion.png.jpg";

import PrimeraPortadaC from "../../images/arquitectura_2023_2/sala 1.jpg";
import SegundaPortadaC from "../../images/arquitectura_2023_2/sala 2.jpg";
import TerceraPortadaC from "../../images/arquitectura_2023_2/sala 3.jpg";
import CuartaPortadaC from "../../images/arquitectura_2023_2/sala 4.jpg";
import QuintaPortadaC from "../../images/arquitectura_2023_2/sala 5.jpg";
import NivelacionPortadaC from "../../images/arquitectura_2023_2/nivelacion.jpg";
import Modal from "./Modal.jsx";
import Nivelacion from './nivelacion';

const Menu = () => {
  const EntranceMenuContainer = useRef(null);
  const Title = useRef(null)
  const Button1 = useRef(null);
  const Button2 = useRef(null);
  const Button3 = useRef(null);
  const Button4 = useRef(null);
  const Button5 = useRef(null);
  const Button6 = useRef(null);

  const [ModalDisplay, setModalDisplay] = useState({
    first: false,
    second: false,
    third: false,
    quarter: false,
    fifth: false,
    sixth: false
  })

  useEffect(() => {
    setTimeout(() => {
      if(Title){
        Title.current.className = `${Title.current.className} TextTitleAnimation`
      }
    }, 7000);
    setTimeout(() => {
      if(Button1){
        Button1.current.className = `${Button1.current.className} FadeInButton`
        Button6.current.className = `${Button1.current.className} FadeInButton`
      }
    }, 8000);
    setTimeout(() => {
      if(Button2){
        Button2.current.className = `${Button2.current.className} FadeInButton`
        Button5.current.className = `${Button2.current.className} FadeInButton`
      }
    }, 8500);
    setTimeout(() => {
      if(Button3){
        Button3.current.className = `${Button3.current.className} FadeInButton`
        Button4.current.className = `${Button3.current.className} FadeInButton`
      }
    }, 9000);
  }, [])


  return (
    <MenuContainer
      ref={EntranceMenuContainer}
    >
      <div className="menu-subcontainer">
        <h2 className="animated-h2" ref={Title}>ARQUITECTURAS DE LA IMAGINACIÓN UPC  <br></br><span > <p >El Decano de la Facultad de Arquitectura de la Universidad Peruana de Ciencias Aplicadas (UPC), tiene el agrado de presentarles la Exposición Virtual de los trabajos finales de los Talleres de Diseño, el área de Expresión Gráfica de nuestra Carrera y el doble grado de Bachiller con The University of Arizona, del ciclo 2023-2.
        <div className="space"></div>Miguel Cruchaga Belaunde agradece su visita.</p></span></h2>
        
        <h2 className="h2-empty">ARQUITECTURAS DE LA IMAGINACIÓN UPC<br></br><span > <p >El Decano de la Facultad de Arquitectura de la Universidad Peruana de Ciencias Aplicadas (UPC), tiene el agrado de presentarles la Exposición Virtual de los trabajos finales de los Talleres de Diseño, el área de Expresión Gráfica de nuestra Carrera y el doble grado de Bachiller con The University of Arizona, del ciclo 2023-2.
        <div className="space"></div>Miguel Cruchaga Belaunde agradece su visita.</p></span></h2>
        <div className="buttons-container">
          <button
            ref={Button1}
          >
            <img src={NivelacionPortada} alt="arquitecturas de la imaginación de UPC - Nivelación" />
            <div className="arquitectura-text">
              <a href='/galeria/arquitectura-upc-2023-2/nivelacion_expresion_grafica'>
                <h3>
                  <img src={NivelacionPortadaC} alt="arquitecturas de la imaginación de UPC - Nivelación" />
                </h3>
              </a>   
            </div>
          </button>
          <button
            ref={Button2}
          >
            <img src={PrimeraPortada} alt="arquitecturas de la imaginación de UPC - Primer año" />
            <div className="arquitectura-text">
            <a href='/galeria/arquitectura-upc-2023-2/primer_año'>
              <h3>
              <img src={PrimeraPortadaC} alt="arquitecturas de la imaginación de UPC - Primer año" />
              </h3>
            </a>
            </div>
          </button>
          <button
            ref={Button3}
          >
            <img src={SegundaPortada} alt="arquitecturas de la imaginación de UPC - Segundo año" />
            <div className="arquitectura-text">
              <a href='/galeria/arquitectura-upc-2023-2/segundo_año'>
                <h3>
                <img src={SegundaPortadaC} alt="arquitecturas de la imaginación de UPC - Segundo año" />
                </h3>
              </a>
            </div>
          </button>
          <button
            ref={Button4}
          >
            <img src={TerceraPortada} alt="arquitecturas de la imaginación de UPC - Tercer año" />
            <div className="arquitectura-text">
              <a href='/galeria/arquitectura-upc-2023-2/tercer_año'>
                <h3>
                <img src={TerceraPortadaC} alt="arquitecturas de la imaginación de UPC - Tercer año" />
                </h3>
              </a>
            </div>
          </button>
          <button
            ref={Button5}
          >
            <img src={CuartaPortada} alt="arquitecturas de la imaginación de UPC - Cuarto año" />
            <div className="arquitectura-text">
              <a href='/galeria/arquitectura-upc-2023-2/cuarto_año'>
                <h3>
                <img src={CuartaPortadaC} alt="arquitecturas de la imaginación de UPC - Cuarto año" /> 
                </h3> 
              </a>
            </div>
          </button>
          <button
            ref={Button6}
          >
            <img src={QuintaPortada} alt="arquitecturas de la imaginación de UPC - Quinto año" />
            <div className="arquitectura-text">
              <a href='/galeria/arquitectura-upc-2023-2/quinto_año'>
                <h3>
                <img src={QuintaPortadaC} alt="arquitecturas de la imaginación de UPC - Quinto año" />  
                </h3>
              </a>
            </div>
          </button>
        </div>
      </div>
    </MenuContainer>
  )
}

export default Menu
