import React, { useEffect, useRef, useState } from 'react'
import Particles from 'react-particles-js';
import Menu from "./Menu";
import {
  ParticleAnimationContainer,
  AnimationEntrance,
  AnimationEntrance2,
  TextEntrance
} from './styles';
import particlesConfig from "./particlesjs-config.json";
import Layout from '../share/layout';

const Arquitecturas = () => {
  const [Overflow, setOverflow] = useState(true)
  const Container = useRef(null);
  const Animation = useRef(null);
  const Animation2 = useRef(null);
  const TextAnimation = useRef(null);
  useEffect(() => {
    TextAnimation.current.setAttribute('displayed', 'false')
    Container.current.setAttribute('showoverflow', 'true')
    if (Animation && Animation.current && Animation.current.className) {
      setTimeout(() => {
        if (Animation && Animation.current && Animation.current.className) {
          Animation.current.className = `${Animation.current.className} OutLeft`
          Animation2.current.className = `${Animation2.current.className} OutRight`
          TextAnimation.current.className = `${TextAnimation.current.className} TextTop`
        }
      }, 2000);
    }
    if (Container && Container.current && Animation.current.className) {
      setTimeout(() => {
        if (Container && Container.current && Animation.current.className) {
          Container.current.setAttribute('showoverflow', 'false')
          TextAnimation.current.setAttribute('displayed', 'true')
          TextAnimation.current.className = `${TextAnimation.current.className.split('TextTop')[0]} TextOutBottom`
        }
      }, 6000);
    }
    setTimeout(() => {
      setOverflow(false)
    }, 8000);
  }, [])
  return (
    <Layout
      title="Arquitectura UPC 2023-2"
      metaTitle="Arquitecturas de la Imaginación 2023-2 [Facultad de Arquitectura UPC]"
      description="Exposición virtual de los mejores trabajos de los estudiantes de los Talleres de Diseño y del área de Expresión Gráfica de la Facultad de Arquitectura de la Universidad Peruana de Ciencias Aplicadas (UPC) del ciclo 2023-2."
      subtitile="Arquitecturas de la Imaginación 2023-2"
      metaDescripcin="Exposición virtual de los mejores trabajos de los estudiantes de los Talleres de Diseño y del área de Expresión Gráfica de la Facultad de Arquitectura de la Universidad Peruana de Ciencias Aplicadas (UPC) del ciclo 2023-2."
      imgurl="//images.ctfassets.net/bv5rnjawitjg/1aQ1UsfwhW95vX6CX3GezG/50c1cfe42bb4504cfea4cf0d9822ef8e/Arq-Imag-22.1_800x800.jpg?h=300"
      keywords="arquitectura, exhibición, Arquitecturas de la imaginación, UPC Cultural, taller de diseño arquitectónico, expresión gráfica, Facultad de arquitectura UPC"
    >
      <ParticleAnimationContainer style={{ overflowY : Overflow ? 'hidden' : 'visible' }} ref={Container}>
        <AnimationEntrance
          ref={Animation}
        />
        <AnimationEntrance2
          ref={Animation2}
        />
        <TextEntrance
          ref={TextAnimation}
        >
          <h1>
          ARQUITECTURAS <br />
            DE LA IMAGINACIÓN<br />
            UPC - 2023 - 2
          </h1>
        </TextEntrance>
        <Particles
          params={particlesConfig}
        />
        <Menu />
      </ParticleAnimationContainer>
    </Layout>
  )
}

export default Arquitecturas
